<template>
  <div>
    <base-table
      :render-list-to-show="listToShow"
      :per-page="50"
      :wrapper-filters-data="wrapperFiltersData"
      :columns="columns"
      :enable-save-csv="true"
      save-csv-url="/alipay/downloadCsv"
    >
      <template v-slot:base-table-actions>
        <div class="topping__btns fx col-12" v-if="canCreateCoupons">
          <div class="dropdown-nominals mr-10">
            <p class="mb-0"><b>Создание купонов</b></p>
            <div class="dropdown" @click="toggleDropdown">
              <div class="dropdown-header">
                {{ selectedNominal || 'Выберите номинал' }}
                <span class="dropdown-arrow"></span>
              </div>
              <div class="dropdown-list" v-if="isDropdownOpen">
                <div class="dropdown-item" v-for="nominal in availableNominal" :key="nominal.id" @click="selectedNominalTitle(nominal)">
                  {{ nominal.title }}
                </div>
              </div>
            </div>
          </div>
          <button type="button" :class="buttonClass" style="height: 50px; margin-top: 15px;" @click="createCoupon">
            Создать 100 купонов с номиналом {{ selectedNominal }}
          </button>
        </div>
      </template>
      <template v-slot:base-table-row="props">
        <div class="admin-table-element">
          <template v-if="props.column.field === 'is_active'">
            <button :class="'admin-table-element' + (props.formattedRow[props.column.field] ? ' text-danger' : ' text-success') ">
              {{ props.formattedRow[props.column.field] == 0 ? 'Да' : 'Нет' }}
            </button>
          </template>
          <template v-else>
            {{ props.formattedRow[props.column.field] }}
          </template>
        </div>
      </template>
    </base-table>
  </div>
</template>

<script>
import BaseTable from "../../../components/baseTable";
import CustomPagination from "../../../components/customPagination";
import Multiselect from "vue-multiselect";
import WrapperFilters from "../../../components/wrapperFilters";
import BaseAdminMixin from "../mixins/BaseAdminMixin";
export default {
  name: "partnerStatistic",
  mixins: [
    BaseAdminMixin,
  ],
  components: {
    CustomPagination,
    Multiselect,
    WrapperFilters,
    BaseTable
  },
  props: [
    'availableNominal',
    'canCreateCoupons',
  ],
  data: function () {
    return {
      selectedNominal: null,
      isDropdownOpen: false,
      buttonClass: 'btn btn-secondary',
      wrapperFiltersData: {
        title: "Alipay coupons",
        filtersUrl: "/alipay/getList",
        filtersUrlMethod: "get",
        items: {
          date: {
            enable: true,
            column: 1,
          },
          dateSelector: {
            enable: true,
            placeholder: 'Период',
            noGroupSelect: true,
            closeOnSelect: true,
            selectedKey: 'dateSelector',
            data: [
              {
                id: 1,
                title: 'За последние 20 минут'
              },
              {
                id: 4,
                title: 'За 1 неделю'
              },
              {
                id: 6,
                title: 'За 2 недели'
              },
              {
                id: 7,
                title: 'За 3 недели'
              },
              {
                id: 5,
                title: 'За месяц'
              },
            ],
            column: 1,
            defaultValue: {
              id: 5,
              title: 'За месяц'
            },
            setDefaultInFilters: 5
          },
          currencyTypes: {
            enable: true,
            placeholder: 'Номинал',
            noGroupSelect: true,
            closeOnSelect: true,
            multiple: true,
            selectedKey: 'nominalTypes',
            column: 2,
            data: this.availableNominal
          },
          coupon: {
            enable: true,
            simpleInputType: 'text',
            placeholder: 'Купон',
            selectedKey: 'coupon',
            column: 2,
          },
          users: {
            enable: true,
            multiple: true,
            asyncRenderList: true,
            getDataLinkAsyncRequest: '/admin/users/getUsersForFiltersByRequest',
            placeholder: 'Пользователи',
            specifyKeyForLabel: 'login',
            viewLabel: 'titleWithId',
            column: 3
          },
        },
      },
      columns: [
        {
          label: 'Номинал',
          field: 'nominal',
          width: '10%',
          sortable: false,
        },
        {
          label: 'Купон',
          field: 'code',
          type: 'number',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Дата генерации',
          field: 'created_at',
          sortable: false,
          width: '15%',
        },
        {
          label: 'Срок действия (до)',
          field: 'expire_date',
          width: '15%',
          sortable: false,
        },
        {
          label: 'Использован(дата)',
          field: 'used_date',
          sortable: true,
          type: 'number',
          width: '15%',
        },
        {
          label: 'Кем использован',
          field: 'used',
          sortable: true,
          type: 'number',
          width: '13%',
        },
        {
          label: 'Активен',
          field: 'is_active',
          sortable: true,
          type: 'number',
          width: '10%',
        },
      ],
    }
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    listToShow: function (list) {
      let data = [];
      for (let elem of list) {
        let used = '';
        let used_date = '';
        if(elem['payment'] != null){
          used = elem['payment']['user']['login'] + ' (' + elem['payment']['user']['id'] + ')'
          used_date = elem['payment']['created_at']
        }
        data.push({
          code: elem['code'],
          created_at: elem['created_at'],
          expire_date: elem['expire_date'],
          nominal: elem['nominal'],
          used: used,
          used_date: used_date,
          is_active: elem['used'],
        });
      }
      return data;
    },
    async createCoupon() {
      let response = await this.sendRequest('alipay/create','post',{nominal: this.selectedNominal});
      if (response) {
        this.convertResponseToDownloadFileCsv(response);
      }
    },
    convertResponseToDownloadFileCsv(response) {
      const url = URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');

      link.href = url;
      let date = new Date();
      let fileName = 'coupons' + date.getHours() + '_' + date.getMinutes() + '.txt'
      link.setAttribute('download', fileName);
      document.body.appendChild(link);

      link.click();
      URL.revokeObjectURL(url);
      link.remove();
    },
    selectedNominalTitle(data) {
      if(data.id) {
        this.buttonClass = 'btn btn-success';
        this.selectedNominal = data.id
      }else{
        this.buttonClass = 'btn btn-secondary';
      }
    }
  }
}
</script>
<style scoped>
.dropdown-nominals select {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  outline: none;
  cursor: pointer;
}

.dropdown-nominals select:focus {
  border-color: #66afe9;
  box-shadow: 0 0 5px rgba(102, 175, 233, 0.6);
}
.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-header {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.dropdown-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  z-index: 1000;
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f1f1f1;
}
</style>
